import { render } from 'react-dom'
import { NavbarMenuOptions } from '../../Navbar/NavbarMenuOptions'

document.addEventListener('DOMContentLoaded', () => {
  const option = document.querySelector('#navbar-mobile-menu-options')

  if (option) {
    render(<NavbarMenuOptions />, option)
  }
})
