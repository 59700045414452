import { CloseIcon } from './CloseIcon'

export const MobileUserOption = ({ open, setOpen }) => (
  <button
    className="navbar-toggler"
    type="button"
    data-bs-target="#account-nav-mobile"
    aria-expanded="false"
    aria-label="Toggle navigation"
    onClick={() => setOpen(!open)}
    style={{ width: '50px', height: '30px', padding: '0px', border: 'none' }}
  >
    {open ? <CloseIcon /> : <UserIcon />}
  </button>
)

const UserIcon = () => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0004 11.7611C13.7879 11.7611 16.0476 9.50136 16.0476 6.71386C16.0476 3.92635 13.7879 1.66663 11.0004 1.66663C8.21285 1.66663 5.95312 3.92635 5.95312 6.71386C5.95312 9.50136 8.21285 11.7611 11.0004 11.7611Z"
      stroke="#343434"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M20.3346 21.0948C20.3346 18.6195 19.3513 16.2455 17.601 14.4951C15.8506 12.7448 13.4767 11.7615 11.0013 11.7615C8.52595 11.7615 6.15198 12.7448 4.40164 14.4951C2.6513 16.2455 1.66797 18.6195 1.66797 21.0948"
      stroke="#343434"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
)
