import { CloseIcon } from './CloseIcon'

export const MobileMenuOption = ({ open, setOpen }) => (
  <button
    aria-controls="main-navbar"
    aria-expanded="false"
    aria-label="Toggle navigation"
    className="navbar-toggler"
    data-bs-target="#main-navbar"
    type="button"
    onClick={() => setOpen(!open)}
    style={{ width: '50px', height: '30px', padding: '0px', border: 'none' }}
  >
    {open ? <CloseIcon /> : <MenuIcon />}
  </button>
)

const MenuIcon = () => (
  <svg
    fill="none"
    height="10"
    viewBox="0 0 28 10"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1L28 1"
      stroke="#343434"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M8 9L28 9"
      stroke="#343434"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
)
