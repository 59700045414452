import { useState } from 'react'
import { MobileUserOption } from '../components/Navbar/MobileUserOption'
import { MobileMenuOption } from '../components/Navbar/MobileMenuOption'

export const NavbarMenuOptions = () => {
  const [userOptionOpen, setUserOptionOpen] = useState(false)
  const [menuOptionOpen, setMenuOptionOpen] = useState(false)

  const toggleAccountNav = () => {
    new bootstrap.Collapse(document.querySelector('#account-nav-mobile'))
  }

  const toggleMainNav = () => {
    new bootstrap.Collapse(document.querySelector('#main-navbar'))
  }

  const handleMenuOption = (value) => {
    if (userOptionOpen) {
      setUserOptionOpen(false)
      toggleAccountNav()
    }
    setMenuOptionOpen(value)
    toggleMainNav()
  }

  const handleUserOption = (value) => {
    if (menuOptionOpen) {
      setMenuOptionOpen(false)
      toggleMainNav()
    }
    setUserOptionOpen(value)
    toggleAccountNav()
  }

  return (
    <div className="d-flex">
      {App.State.userSignedIn && (
        <MobileUserOption open={userOptionOpen} setOpen={handleUserOption} />
      )}
      <MobileMenuOption open={menuOptionOpen} setOpen={handleMenuOption} />
    </div>
  )
}
