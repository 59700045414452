export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <g opacity="1">
      <path
        d="M1 21L21 1"
        stroke="#343434"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M21 21L1 1"
        stroke="#343434"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
)
